window.app.factory('ErrFactory', ['$translate', 'Notification', function($translate, Notification) {

  class ErrFactory {
    title: string;
    message: string;
    translateParams: any;
    response: any;
    type: string;
    constructor(message?, isLabourLawError?, translateParams?){
      this.title = 'note';
      this.message = message || (this.constructor as any).DEFAULT_MESSAGE || '';
      this.translateParams = translateParams || {};

      const stack = (new Error(message)).stack;

      Object.defineProperty(this, 'stack', { get: () => stack });
    }

    get desc() {
      return this.message;
    }

    set desc(str) {
      this.message = str;
    }

    notify(callback?) {
      return this._translate()
      .then(() => {
        return new Promise((res,rej) => {
          Notification.alert({
            title: this.title,
            desc: this.desc
          }, (arg) => {
            res(arg);
            if (callback && typeof(callback) === "function") callback(arg);
          })
        });
      })
    }

    confirm (buttons = ['cancel','ok']) {
      return this._translate(buttons)
      .then((buttons) => {
        return new Promise((res,rej) => {
          let data = {
            title: this.title,
            desc: this.desc,
            buttons
          }

          Notification.confirm(data, (ans) => {
            res(ans);
          })
        });
      })
    }

    setResponse(response) {
      this.response = response;
    }

    translate(callback: Function = () => {}) {
      return new Promise((res,rej) => {
        $translate([this.title, this.desc]).then((translation) => {
          let keys = _.keys(translation);
          this.title = translation[keys[0]];
          this.desc = translation[keys[1]];

          res(this);
          return callback(this);
        });
      });
    }

    _translate (ext = []) {
      let list = [this.title, this.desc];
      list = list.concat(ext);

      return new Promise((res,rej) => {
        $translate(list, this.translateParams).then((translation) => {
          this.title = translation[list.shift()];
          this.desc = translation[list.shift()];

          res(list.map( (item) => translation[item] ));
        })
      });
    }

    static get UnavailableServiceError()    { return UnavailableServiceError;    }
    static get UnknownServerError()         { return UnknownServerError;         }
    static get ServerConnectionError()      { return ServerConnectionError;      }
    static get NoInternetConnectivity()     { return NoInternetConnectivity;     }
    static get QuietError()                 { return QuietError;                 }
    static get StoragePermission()          { return StoragePermission;          }
    static get DeniedPermissionForStorage() { return DeniedPermissionForStorage; }
    static get DeniedPermissionForCamera()  { return DeniedPermissionForCamera;  }
    static get FsIsNotDefined()             { return FsIsNotDefined;             }
    static get RegisterError()              { return RegisterError;              }
    static get AuthFilure()                 { return AuthFilure;                 }
    static get passwordConfirmation()       { return PasswordConfirmation;       }
    static get FileTransferError()          { return FileTransferError;          }
    static get FileSystemError()            { return FileSystemError;            }
    static get ErrLocalDB()                 { return ErrLocalDB;                 }
    static get NoEBS()                      { return NoEBS;                      }
    static get ConfirmEBS()                 { return ConfirmEBS;                 }
    static get FutureAR()                   { return FutureAR;                   }
    static get BrokenFile()                 { return BrokenFile;                 }

    static createByObject(obj) {
      let err = new ErrFactory('');
      err.title = obj.title;
      err.desc = obj.desc;
      return err;
    }

    static createForNative(errKey, mess) {
      let messKey = 'native_error.messages.' + mess;
      let err = new ErrFactory('');

      return new Promise((res, rej) => {
        $translate([errKey, messKey]).then((t) => {
          if (messKey === t[messKey]) {
            err.title = t[errKey];
            err.desc = mess;
            return res(err);
          } else {
            err.title = t[errKey];
            err.desc = t[messKey];
            return res(err);
          }
        })
      });
    }

    static createForBreakOfProgress() {
      let err = new ErrFactory('');
      err.title = 'Break of progress';
      err.type = 'BREAK_OF_PROGRESS';
      err.notify = function(callback) {
        if (typeof callback === 'function') {
          return callback();
        }
      };

      return err;
    }
  }

  class FileTransferError extends ErrFactory {
    constructor (err) {
      let code;

      switch (+err.code) {
        case 1:
          code = '1 - FILE_NOT_FOUND_ERR';
          break;
        case 2:
          code = '2 - INVALID_URL_ERR';
          break;
        case 3:
          code = '3 - ERR_CONNECTION';
          break;
        case 4:
          code = '4 - ABORT_ERR';
          break;
        case 5:
          code = '5 - NOT_MODIFIED_ERR'
          break;
        default:
          code = '3 - ERR_CONNECTION';
      }

      super('errors.vcard_tranfer', false, { code });
    }
  }

  class FileSystemError extends ErrFactory {
    code:Number;

    constructor (err) {
      let code;

      switch (+err.code) {
        case 1:
          code = '1 - NOT_FOUND_ERR';
          break;
        case 2:
          code = '2 - SECURITY_ERR';
          break;
        case 3:
          code = '3 - ABORT_ERR';
          break;
        case 4:
          code = '4 - NOT_READABLE_ERR';
          break;
        case 5:
          code = '5 - ENCODING_ERR';
          break;
        case 6:
          code = '6 - NO_MODIFICATION_ALLOWED_ERR';
          break;
        case 7:
          code = '7 - INVALID_STATE_ERR';
          break;
        case 8:
          code = '8 - SYNTAX_ERR';
          break;
        case 9:
          code = '9 - INVALID_MODIFICATION_ERR';
          break;
        case 10:
          code = '10 - QUOTA_EXCEEDED_ERR';
          break;
        case 11:
          code = '11 - TYPE_MISMATCH_ERR';
          break;
        case 12:
          code = '12 - PATH_EXISTS_ERR';
          break;
        default:
          code = '3 - ABORT_ERR';
      }
      super('errors.fileSystem', false);
      this.code = +err.code;
    }
  }


  class UnknownServerError extends ErrFactory {
    constructor(status){
      super('errors.unknownServerError',false, {status});
    }
  }

  class UnavailableServiceError extends ErrFactory {
    static __inited: any;
    constructor(message, isWarning){
      if (UnavailableServiceError.__inited) {
        const res: UnavailableServiceError = new QuietError();
        return res;
      }
      super(message, isWarning);
      this.title = 'maintenance';
      this.message = 'content';
    }

    notify(callback) {
      UnavailableServiceError.__inited = true;
      return this._translate()
        .then(() => {
          return new Promise((res,rej) => {
            Notification.alert({
              title: this.title,
              desc: this.desc
            }, (arg) => {
              res(arg);
              if (callback && typeof(callback) === "function") callback(arg);
            })
          });
        })
        .then(() => UnavailableServiceError.__inited = false);
    }
  }

  class ServerConnectionError extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.message = 'errors.serverConnectionError';
    }
  }

  class NoInternetConnectivity extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.title = 'errors.noInternetConnection';
      this.message = 'errors.youHaveNoConnection';
    }
  }

  class StoragePermission extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.title = '';
      this.message = 'requestPermissionForStorage';
    }
  }

  class DeniedPermissionForStorage extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.message = 'native_error.messages.permission_storage_denied';
    }
  }

  class DeniedPermissionForCamera extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.message = 'native_error.messages.permission_camera_denied';
    }
  }

  class FsIsNotDefined extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);
      this.title = 'errors.fsIsNotDefined';
      this.message = '';
    }
  }

  class ErrLocalDB extends ErrFactory {
    constructor(){
      super('', true);
      this.message = 'errors.localDB';
    }
  }

  class QuietError extends ErrFactory {
    constructor(message?, isWarning?){
      super(message, isWarning);

      this.title = 'Quiet error';
      //this.message = '';
    }

    notify (callback?) {
      console.error(this);
      return Promise.resolve(callback);
    }
  }

  class RegisterError extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);
      if (message === 'accept_privacy_policy') {
        this.message = 'errors.registerDataPrivacy';
      } else {
        this.message = 'errors.registerFields';
      }

    }
  }

  class AuthFilure extends ErrFactory {
    constructor () {
      super('', false);
      this.title = 'errors.authFilure';
    }
  }

  class PasswordConfirmation extends ErrFactory {
    constructor () {
      super();
      this.title = 'note';
      this.message = 'errors.samePasswords';

    }
  }

  class NoEBS extends ErrFactory {
    constructor () {
      super();
      this.title = 'timeTrackings.noEbsAssigned';
      this.message = 'timeTrackings.noEbsAssignedBody';
    }
  }

  class ConfirmEBS extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.title = 'note';
      this.message = 'timeTrackings.ebsNotConfirmed';
    }
  }

  class FutureAR extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.title = 'note';
      this.message = 'timeTrackings.noFutureActivityReport';
    }
  }

  class BrokenFile extends ErrFactory {
    constructor(message, isWarning){
      super(message, isWarning);

      this.title = 'note';
      this.message = 'timeTrackings.corruptedFile';
    }
  }

  return ErrFactory;
}]);
