app.filter('timeToDecimals', function ($filter) {
    return function (input) {
      let time = Number(input);
        if (!Number.isNaN(time)) {
            let number = time/(60*60*1000);
            return $filter('number')(number, 2).replace(".",",");
        }
        else return '00,00';
    }
});
