app.filter('totalTime', function () {
    return function (input) {
      let time = Number(input);

      if (!Number.isNaN(time)) {
        let str = numeral(time/1000).format('00:00');
        return str.replace(/:\d{2}$/,'');
      } else return '00:00';
    }
});
