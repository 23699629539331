import { TimeTrackingModel, TimeTrackingDataModel } from '../models/time-tracking.model';
import { PauseDataModel } from '../models/pause.model';
import { AssignmentModel } from '../models/assignment.model';

window.app.factory('TimeTrackingFactory', ['DBFactory', 'DatabaseService', 'AuthService', 'AssignmentFactory', 'ErrFactory',
                                  function (DBFactory,   DatabaseService,   AuthService,   AssignmentFactory,   ErrFactory) {

  class Pause {
    id:     number;
    tt:     TimeTracking;
    _start: Date;
    _end:   Date;
    constructor (data: PauseDataModel, tt: TimeTracking) {
      this.id    = data.id || null;
      this.tt    = tt;
      this.start = this.parseDateTime(data.start);
      this.end   = this.parseDateTime(data.end);
    }

    set start(date)   { this._start = this.prepareDate(date); }
    set end  (date)   { this._end   = this.prepareDate(date); }

    get start(): Date { return this._start; }
    get end():   Date { return this._end;   }
  

    get duration(): number {
      if (this.start && this.end) return Math.max(0, +this.end - +this.start);
      else return 0;
    }

    private prepareDate(date): Date {
      date = this.parseDateTime(date);
      if (date) {
        if (date.getTime() < this.tt.start_date.getTime()) date = TimeTracking.timeForDate(date, this.tt.start_date);
        date = this.considerOvernight(date, this.tt.start_date);
      }
      return date;
    }

    toJSON(): PauseDataModel {
      return Object.assign({}, {
        id:    this.id || null,
        start: this.start instanceof Date ? this.start.toISOString() : null,
        end:   this.end   instanceof Date ? this.end.toISOString()   : null
      });
    }

    updateDate(date: Date): void {
      if (this.start instanceof Date) this.start = TimeTracking.timeForDate(this.start, date);
      if (this.end   instanceof Date) this.end   = TimeTracking.timeForDate(this.end,   date);
    }

    private considerOvernight(dateTime: Date, startDay: Date): Date {
      let ONE_DAY = 24 * 60 * 60 * 1000;
      let date = new Date(dateTime);
      if (+date - +startDay > ONE_DAY) date.setDate(date.getDate() - 1);
      else if (date < startDay) date.setDate(date.getDate() + 1);
      return date;
    }

    private parseDateTime(date: unknown): Date {
      return date instanceof Date ? date : typeof date === 'string' ? new Date(date) : null;
    }
  }

  class TimeTracking {
    id:                     number;
    user_id:                number;
    start_date:             Date;
    end_date:               Date;
    pauses:                 Pause[];
    assignment:             AssignmentModel;

    project:                string;
    notes:                  string;

    holidays:               any[];

    valid_mileage_money:    boolean;
    mileage_money_report:   any;
    original_time_frame_id: number;
    
    static COLECTION_NAME:  string;
    constructor (data: TimeTrackingDataModel) {
      this.id                     = data.id      || null;
      this.user_id                = data.user_id || null;
      this.start_date             = this.parseDateTime(data.start_date);
      this.end_date               = this.parseDateTime(data.end_date);
      this.pauses                 = data.pauses ? data.pauses.map(p => new Pause(p, this)) : [];
      this.assignment             = data.assignment || data.job ? new AssignmentFactory(data.assignment || data.job) : null;

      this.project                = data.project    || '';
      this.notes                  = data.notes      || '';

      this.holidays               = data.holidays ? data.holidays : [];
      
      this.mileage_money_report   = data.mileage_money_report   || null;
      this.valid_mileage_money    = data.valid_mileage_money    || null;
      this.original_time_frame_id = data.original_time_frame_id || null;
    }

    private parseDateTime(date: unknown): Date {
      return date instanceof Date ? date : typeof date === 'string' ? new Date(date) : null;
    }

    get assignmentId() {
      if (this.assignment) return this.assignment.id;
      else return null;
    }

    set startDate(date) {
      date = this.parseDateTime(date);
      if (date) {
        date.setHours(0,0,0,0);

        this.updateDate(date);
        if (!this.start_date) this.start_date = date;
        if (!this.end_date)   this.end_date   = date;
      }
    }

    set startTime(dateTime) {
      dateTime = this.parseDateTime(dateTime);

      if (dateTime) {
        let date = this.start_date || new Date;
        this.updateDate(date);

        dateTime.setFullYear(date.getFullYear(),date.getMonth(),date.getDate());
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
      }

      this.start_date = dateTime;
    }

    set endTime(dateTime) {
      dateTime = TimeTracking.parseDateTime(dateTime);

      if (dateTime) {
        let date = this.start_date;
        if (date instanceof Date) {
          dateTime.setFullYear(date.getFullYear(),date.getMonth(),date.getDate());
          dateTime = this.considerOvernight(dateTime, this.start_date)
        }
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
      }

      this.end_date = dateTime;
    }

    get totalDurationTime(): number {
      if (this.start_date && this.end_date) return Math.max(+this.end_date - +this.start_date, 0);
      else return 0;
    }

    get pausesDuration(): number { return this.pauses.reduce((sum, p) => sum + p.duration, 0); }
    get duration():       number { return Math.max(this.totalDurationTime - this.pausesDuration, 0); }
    get lastPause():      Pause  { return this.sortedPauses.pop(); }

    get sortedPauses(): Pause[] {
      let p = [...this.pauses];

      p.sort((a, b) => {
        if      (a.start < b.start) return -1;
        else if (a.start > b.start) return 1;
        else return 0;
      });

      return p;
    }

    private considerOvernight(dateTime, startDay): Date {
      let ONE_DAY = 24 * 60 * 60 * 1000;
      let date = dateTime;
      if (date - startDay > ONE_DAY) date.setDate(date.getDate() - 1);
      else if (date < startDay) date.setDate(date.getDate() + 1);
      return date;
    }

    updateDate(date: Date): void {
      if (this.start_date instanceof Date) this.start_date = TimeTracking.timeForDate(this.start_date, date);
      if (this.end_date   instanceof Date) this.end_date   = TimeTracking.timeForDate(this.end_date,   date);

      this.pauses.forEach((p) => p.updateDate(date));
    }

    save(): TimeTracking {
      if (AuthService.userId) this.user_id = AuthService.userId;
      let data = this.toJSON();
      if (!data.id) delete data.id;

      return DBFactory.then(ds => ds.db)
      .then(db => db.put(TimeTracking.COLECTION_NAME, data))
      .then(id => this.id = id)
      .then(() => this);
    }

    remove() {
      if (this.id) return TimeTracking.removeById(this.id);
      else return Promise.resolve();
    }

    pausesToJSON(pauses: Pause[]): PauseDataModel[] {
      return pauses.map((p, index) => {
        p.id = index + 1;
        return p.toJSON();
      });
    }

    toJSON(): TimeTrackingModel {
      return Object.assign({
        id:                     this.id,
        user_id:                this.user_id,
        assignment_id:          this.assignment && this.assignment.id,
        start_date:             this.start_date instanceof Date ? this.start_date.toISOString() : null,
        end_date:               this.end_date   instanceof Date ? this.end_date.toISOString()   : null,
        pauses:                 this.pausesToJSON(this.pauses),
        notes:                  this.notes,
        project:                this.project,
        holidays:               this.holidays,
        valid_mileage_money:    this.valid_mileage_money,
        mileage_money_report:   this.mileage_money_report,
        original_time_frame_id: this.original_time_frame_id,
      });
    }

    toSubmitData() {
      return Object.assign({}, {
        activity:   this.project,
        started_at: this.start_date instanceof Date ? this.start_date.toISOString() : null,
        ended_at:   this.end_date   instanceof Date ? this.end_date.toISOString()   : null,
        notes:      this.notes,
        pauses:     this.pausesToJSON(this.pauses)
      });
    }

    static getOwn(): Promise<TimeTrackingModel[]> {
      return DatabaseService.getOwn(TimeTracking.COLECTION_NAME)
      .then(list => Promise.all(
        list.map((item) => this.getByDbItem(item))
      ));
    }

    static getByDbItem(dbData): Promise<TimeTrackingModel> {
      return AssignmentFactory.getById(dbData.assignment_id || dbData.job_id)
      .then((assignment) => new this(Object.assign(dbData, { assignment })));
    }

    static getById(id): Promise<TimeTrackingDataModel> {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.get(TimeTracking.COLECTION_NAME,id))
      .then((item) => this.getByDbItem(item));
    }

    static parseDateTime(dateTime, startDay = false, endDay = false): Date {
      let date = null;
      if      (dateTime instanceof Date)     date = new Date(dateTime);
      else if (typeof dateTime === 'string') date = new Date(dateTime);
      else return null;

      if      (startDay) date.setHours(0,0,0,0);
      else if (endDay)   date.setHours(23,59,59,0);

      return date;
    }

    static timeForDate(time, data): Date {
      let dateTime = new Date(data);
      dateTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
      return dateTime;
    }

    static removeById(id) {
      return DBFactory.then((ds) => ds.db)
      .then(db => db.remove(this.COLECTION_NAME, id))
      .catch(err => {
        throw new ErrFactory.ErrLocalDB;
      });
    }
  }

  Object.defineProperty(TimeTracking, 'COLECTION_NAME', { value: 'time_trackings' });
  Object.defineProperty(TimeTracking, 'Pause',          { value: Pause            });

  return TimeTracking;
}]);
