var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

window.app.factory('LoaderFactory', function() {
  let isActive = false;

  let description = '';

  let style = 'white';

  let message = '';

  let flagInterruption = false;

  let showCancelText = false;

  let progress = {
    visible: false,
    value: 0,
    label: ''
  };

  class Loader extends EventEmitter {

    setProgressMessage = function(str) {
      message = str;
      return this.emitEvent('updated:progressmessage');
    };

    getProgressMessage = function() {
      return message;
    };

    setPreloaderMessage = function(str) {
      message = str;
      return this.emitEvent('updated:preloadermessage');
    };

    getPreloaderMessage = function() {
      return message;
    };

    setInterruption = function() {
      flagInterruption = !flagInterruption;
      return this.emitEvent('updated:Interruption');
    };

    getInterruption = function() {
      return flagInterruption;
    };

    show = function(_style) {
      if (_style == null) {
        _style = 'white';
      }
      this.initHideCancelButtonText();
      flagInterruption = false;
      style = _style;
      isActive = true;
      this.setPreloaderMessage('');
      this.emitEvent('show');
      return this.emitEvent('updated:Interruption');
    };

    hide = function() {
      isActive = false;
      this.setPreloaderMessage('');
      this.emitEvent('hide');
      return this.setDescription();
    };

    getStyle = function() {
      return style;
    };

    showProgress = function() {
      this.initHideCancelButtonText();
      progress.visible = true;
      flagInterruption = false;
      this.setProgressMessage('');
      this.setProgressValue(0);
      this.setProgressLabel('');
      this.emitEvent('showPregress');
      return this.emitEvent('updated:Interruption');
    };

    hideProgress = function() {
      this.setProgressMessage('');
      progress.visible = false;
      return this.emitEvent('hidePregress');
    };

    isVisibleProgress = function() {
      return progress.visible;
    };

    setProgressValue = function(value) {
      if (typeof value === 'number') {
        value = Math.round(value);
      }
      progress.value = value || 0;
      return this.emitEvent('updated:progressvalue');
    };

    getProgressValue = function() {
      return progress.value;
    };

    getProgressLabel = function() {
      return progress.label;
    };

    setProgressLabel = function(str) {
      progress.label = str || '';
      return this.emitEvent('updated:progresslabel');
    };

    isActive = function() {
      return isActive;
    };

    setDescription = function(text) {
      description = text || '';
      return this.emitEvent('updated:description');
    };

    getDescription = function() {
      return description;
    };

    handleCancelButtonText = function() {
      return showCancelText;
    };

    initHideCancelButtonText = function() {
      showCancelText = false;
      return this.emitEvent('handleCancelText');
    };

    initShowCancelButtonText = function() {
        showCancelText = true;
        return this.emitEvent('handleCancelText');
      };
  }

  return new Loader;
});
