import { VacationRequestModel, VacationRequestBasicModel } from '../models/vacation-request';

window.app.factory('VacationRequestFactory', ['DBFactory', 'AuthService', 'AssignmentFactory',
                                     function (DBFactory,   AuthService,   AssignmentFactory ) {
  class VacationRequest {
    id:             number;
    leaveType:      string;

    startsOn:       Date;
    endsOn:         Date;

    reason:         string;
    reasonComment:  string;

    customerReview: string; 
    assignment:     any;
    static COLECTION_NAME = 'vacation_requests';
    constructor (data: VacationRequestBasicModel) {
      this.id             = data.id;

      this.startsOn       = this.parceDate(data.starts_on);
      this.endsOn         = this.parceDate(data.ends_on);
      this.leaveType      = data.leave_type;

      this.reason         = data.reason;
      this.reasonComment  = data.reason_comment;

      this.customerReview = data.customer_review;
      this.assignment     = data.assignment ? new AssignmentFactory(data.assignment) : null;
    }

    parceDate(date: Date | string | number): Date {
      return date ? date instanceof Date ? date : new Date(date) : null;
    }

    toJSON(): VacationRequestBasicModel {
      return Object.assign({}, {
        id:              this.id                       ? this.id                     : null,
        starts_on:       this.startsOn instanceof Date ? this.startsOn.toISOString() : null,
        ends_on:         this.endsOn   instanceof Date ? this.endsOn.toISOString()   : null,
        leave_type:      this.leaveType                ? this.leaveType              : null,
        reason:          this.reason                   ? this.reason                 : null,
        reason_comment:  this.reasonComment            ? this.reasonComment          : null,
        customer_review: this.customerReview           ? this.customerReview         : null,
        assignment:      this.assignment               ? this.assignment             : null
      });
    }

    save(): Promise<VacationRequest> {
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put(VacationRequest.COLECTION_NAME, this.toJSON()))
      .then(() => this);
    }

  }

  class VacationRequestSubmitted extends VacationRequest {
    userId:                     number;
    createdAt:                  Date;

    holidays:                   any[]
    state:                      string;
    parcedState:                string;

    customerReview:             string;
    customerReviewedAt:         Date;
    reviewedByCustomer:         any;
    customerRejectionReason:    string;
    customerReviewComment:      string;

    internalReview:             string;
    internalReviewedAt:         Date;
    reviewedByInternal:         any;
    internalRejectionReason:    string;
    internalReviewComment:      string;

    archivedBySystem:           boolean;

    belongsToInternalLocations: boolean;
    constructor (data: VacationRequestModel) {
      super(data);
      this.userId                     = data.user_id || AuthService.userId || null;
      this.createdAt                  = this.parceDate(data.created_at);
      this.state                      = data.state;

      this.holidays                   = data.holidays;

      this.customerReview             = data.customer_review;
      this.customerReviewedAt         = super.parceDate(data.customer_reviewed_at);
      this.reviewedByCustomer         = data.reviewed_by_customer;
      this.customerRejectionReason    = data.customer_rejection_reason;
      this.customerReviewComment      = data.customer_review_comment;

      this.internalReview             = data.internal_review;
      this.internalReviewedAt         = this.parceDate(data.internal_reviewed_at);
      this.reviewedByInternal         = data.reviewed_by_internal;
      this.internalRejectionReason    = data.internal_rejection_reason;
      this.internalReviewComment      = data.internal_review_comment;

      this.archivedBySystem           = data.state === 'archived_by_system';
      this.parcedState                = this.getState();

      this.belongsToInternalLocations = data.belongs_to_internal_locations;
    }

    toJSON(): VacationRequestModel {
      return Object.assign(super.toJSON(), {
        user_id:                       this.userId                             ? this.userId                           : null,
        created_at:                    this.createdAt          instanceof Date ? this.createdAt.toISOString()          : null,
        holidays:                      this.holidays                           ? this.holidays                         : null,
        state:                         this.state                              ? this.state                            : null,

        customer_review:               this.customerReview                     ? this.customerReview                   : null,
        customer_reviewed_at:          this.customerReviewedAt instanceof Date ? this.customerReviewedAt.toISOString() : null,
        customer_rejection_reason:     this.customerRejectionReason            ? this.customerRejectionReason          : null,
        customer_review_comment:       this.customerReviewComment              ? this.customerReviewComment            : null,
        reviewed_by_customer:          this.reviewedByCustomer                 ? this.reviewedByCustomer               : null,

        internal_review:               this.internalReview                     ? this.internalReview                   : null,
        internal_reviewed_at:          this.internalReviewedAt instanceof Date ? this.internalReviewedAt.toISOString() : null,
        internal_rejection_reason:     this.internalRejectionReason            ? this.internalRejectionReason          : null,
        internal_review_comment:       this.internalReviewComment              ? this.internalReviewComment            : null,
        reviewed_by_internal:          this.reviewedByInternal                 ? this.reviewedByInternal               : null,

        belongs_to_internal_locations: this.belongsToInternalLocations         ? this.belongsToInternalLocations       : null,
      });
    }

    private getState(): string {
      return this.archivedBySystem ? 'rejected' : this.internalReview ? this.internalReview : 'open';
    }

  }

  Object.defineProperty(VacationRequest, 'VacationRequestSubmitted', { value: VacationRequestSubmitted });
  return VacationRequest;
}]);
