window.app.factory('ReleasesFactory', ['ErrFactory', 'DBFactory', function(ErrFactory, DBFactory) {
  class Release {
    id:          number;
    title:       string;
    version:     string;
    application: string;
    notes:       string;

    created_at:  Date;
    released_at: Date;
    constructor(data: any) {
      this.id          = data.id;
      this.title       = data.title;
      this.version     = data.version;
      this.application = data.application;
      this.notes       = data.notes.split('- ').map(n => n.trim()).filter(n => n);

      this.created_at  = new Date(data.created_at);
      this.released_at = new Date(data.released_at);
    }

    save(callback = Function.prototype) {
      let release = Object.assign({}, this);
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put('releases', release))
      .then(() => callback())
      .catch(() => {
        let err = new ErrFactory('errors.localDB');
        callback(err);
        return Promise.reject(err);
      });
    };

  }

  return Release;
}]);
