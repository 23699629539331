window.app.factory('UnsupportedBrowsersDialogFactory', [function() {
    this.isShowUnsupportedBrowsersDialog = false;

    this.show = () => {
        this.isShowUnsupportedBrowsersDialog = true;
    };

    this.hide = () => {
        this.isShowUnsupportedBrowsersDialog = false;
    };

    return this;
}]);
