window.app.factory('PNListFactory', ['ErrFactory', 'DBFactory', function(ErrFactory, DBFactory) {
  class PN {
    id:         string;
    status:     string;
    created_at: Date;
    text:       string;
    subject:    string;
    constructor(data: any) {
      this.id         = data.id;
      this.status     = data.status;
      this.created_at = new Date(data.created_at);
      this.text       = data.text;
      this.subject    = data.subject;
    }

    save(callback = Function.prototype) {
      let notification = Object.assign({}, this);
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put('notifications', notification))
      .then(() => callback())
      .catch(() => {
        let err = new ErrFactory('errors.localDB');
        callback(err);
        return Promise.reject(err);
      });
    };

  }

  return PN;
}]);
