app.filter('weekNumber', function ($filter) {
    let angularDateFilter = $filter('date');
    return function (input) {
      let wn = 0;
      if (input) {
        wn = Number(angularDateFilter(new Date(input), 'w'));
        if (wn === 53) wn = 1;
      }
      return input && wn || '';
    }
});
