window.app.factory('SplashLoaderFactory', function() {
  class SplashLoader {
    _isShow = false;
    showCallback = null;
    hideCallback = null;

    setVariable(_var, value, callback) {
      _var = value;
      if(typeof callback == 'function') {
        callback();
      }
    }

    isShow() {
      return this._isShow;
    }

    show() {
      this.setVariable(this._isShow, true, this.showCallback);
    }

    hide() {
      this.setVariable(this._isShow, false, this.hideCallback);
    }

    onShow(callback)  {
      this.showCallback = callback;
    }

    onHide(callback) {
      this.hideCallback = callback;
    }
  }
  return new SplashLoader();
});
