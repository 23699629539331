import { Collection, DBSystem } from '../services/db_system';

const dbs = new DBSystem('APPsoluteMobility');

dbs
// .insertCollection(new Collection({
//   shema: {
//     name: 'codes',
//     keyPath: 'appCode',
//     type: 'TEXT',
//     autoIncrement: false,
//     unique: true,
//     indexes: [
//       {
//         name: 'user_id',
//         keyPath: ['user_id']
//       },
//       {
//         name: 'project_id',
//         keyPath: 'project_id',
//         type: 'INTEGER',
//         unique: false
//       }
//     ]
//   }
// }))
// .insertCollection(new Collection({
//   shema: {
//     name: 'projects',
//     keyPath: 'id',
//     type: 'INTEGER',
//     autoIncrement: false,
//     unique: true
//   }
// }))
// .insertCollection(new Collection({
//   shema: {
//     name: 'trackings',
//     keyPath: 'trackingId',
//     type: 'TEXT',
//     indexes: [
//       {
//         keyPath: 'code',
//         type: 'TEXT'
//       }
//     ]
//   }
// }))
.insertCollection(new Collection({
  shema: {
    name: 'assets',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: true,
    unique: true
  }
}))
// .insertCollection(new Collection({
//   shema: {
//     name: 'project_assets',
//     keyPath: 'id',
//     type: 'INTEGER',
//     autoIncrement: true,
//     unique: true,
//     indexes: [
//       {
//         keyPath: 'appCode',
//         type: 'TEXT'
//       }
//     ]
//   }
// }))
.insertCollection(new Collection({
  shema: {
    name: 'lead_assets',
    keyPath: 'id',
    autoIncrement: false,
    unique: true
  }
}))
// .insertCollection(new Collection({
//   shema: {
//     name: 'attachments',
//     keyPath: 'id',
//     indexes: [
//       {
//         name: 'trackingId',
//         keyPath: 'trackingId',
//         type: 'TEXT'
//       }
//     ]
//   }
// }))
// .insertCollection(new Collection({
//   shema: {
//     name: 'users',
//     keyPath: 'id',
//     type: 'INTEGER',
//     autoIncrement: false,
//     unique: true
//   }
// }))
// .insertCollection(new Collection({
//   shema: {
//     name: 'activity_tracker',
//     keyPath: 'id',
//     type: 'INTEGER',
//     autoIncrement: true,
//     unique: true
//   }
// }))
.insertCollection(new Collection({
  shema: {
    name : "time_tracking_jobs",
    keyPath: 'id',
    type: 'INTEGER',
    unique: true,
    indexes: [
      {
        name: "customer",
        keyPath: "customer",
        type: 'INTEGER',
        unique: false,
      },
      {
        name: 'user_id',
        keyPath: 'user_id',
        type: 'INTEGER'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'time_trackings',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: true,
    unique: true,
    indexes: [
      {
        name: 'job_id',
        keyPath: 'job_id',
        type: 'INTEGER',
        unique: false
      },
      {
        name: 'assignment_id',
        keyPath: 'assignment_id',
        type: 'INTEGER',
        unique: false
      },
      {
        name: 'user_id',
        keyPath: 'user_id',
        unique: false,
        type: 'INTEGER'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'activity_reports',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: true,
    unique: true,
    indexes: [
      {
        name: 'job_id',
        keyPath: 'job_id',
        type: 'INTEGER',
        unique: false
      },
      {
        name: 'assignment_id',
        keyPath: 'assignment_id',
        type: 'INTEGER',
        unique: false
      },
      {
        name: 'user_id',
        keyPath: 'user_id',
        unique: false,
        type: 'INTEGER'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'users',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'working_periods',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true,
    indexes: [
      {
        name: 'user_id',
        keyPath: 'user_id',
        type: 'INTEGER'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'ebs',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true,
    indexes: [
      {
        name: 'assignment_id',
        keyPath: 'assignment_id',
        type: 'INTEGER'
      },
      {
        name: 'user_id',
        keyPath: 'user_id',
        type: 'INTEGER'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'holidays',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: true,
    indexes: [
      {
        name: 'state_iso',
        keyPath: 'state_iso',
        unique: false,
        type: 'TEXT'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'releases',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'notifications',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'vacation_requests',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true,
    indexes: [
      {
        name: 'user_id',
        keyPath: 'user_id',
        unique: false,
        type: 'INTEGER'
      }
    ]
  }
}))
.insertCollection(new Collection({
  shema: {
    name: 'unread_notifications',
    keyPath: 'id',
    type: 'INTEGER',
    autoIncrement: false,
    unique: true,
    indexes: [
      {
        name: 'user_id',
        keyPath: 'user_id',
        unique: false,
        type: 'INTEGER'
      }
    ]
  }
}))

export const DBFactory = app.AM.deviceready
.then(() => dbs.getDB())
.then((db) => {
  window.db = db;
  return db;
});

window.app.factory('DBFactory', async function() {
  let db = await DBFactory;
  return {db}
});