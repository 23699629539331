app.filter('weekPeriod', function ($filter) {
    let angularDateFilter = $filter('date');
    return function (input) {
        let start = new Date(Weeks.getStartOfWeek(input));
        let startDay = ("0" + start.getDate()).slice(-2);
        let startMonth = ("0" + (start.getMonth() + 1)).slice(-2);
        let end = new Date(Weeks.getEndOfWeek(input));
        let endDay = ("0" + end.getDate()).slice(-2);
        let endMonth = ("0" + (end.getMonth() + 1)).slice(-2);
        return `(${startDay}.${startMonth === endMonth ? '' : startMonth + '.'} - ${endDay}.${endMonth}.)`;
    }
});
