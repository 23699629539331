window.app.factory('OverlayFactory', ['$rootScope', function ($rootScope) {
  class OverlayFactory {
    _isActive = false;
  
    isActive() {
      return this._isActive;
    }
  
    show() {
      this._isActive = true;
    }
  
    hide() {
      this._isActive = false;
    }
  
    handler() {
      $rootScope.DropDownList.hide()
      $rootScope.DropDownMenu.hide()
    }
  }

  return new OverlayFactory;
}]);
