window.app.factory('ValidationErrorFactory', [function() {
  class ValidationErrorFactory {
    index:          number;
    pIndex:         number;
    message:        string;
    messageOptions: unknown;

    dailyId:        number;
    dailyStart:     boolean;
    dailyEnd:       boolean;

    pauseId:        number;
    pauseStart:     boolean;
    pauseEnd:       boolean;
    constructor({ index, pIndex, message, messageOptions, dailyId,  dailyStart, dailyEnd, pauseId, pauseStart, pauseEnd }) {
      this.index          = index;
      this.pIndex         = pIndex;
      this.message        = message;
      this.messageOptions = messageOptions;
  
      this.dailyId        = dailyId;
      this.dailyStart     = dailyStart;
      this.dailyEnd       = dailyEnd;
  
      this.pauseId        = pauseId;
      this.pauseStart     = pauseStart;
      this.pauseEnd       = pauseEnd;
    }

    static get TechnicalError()       { return TechnicalError;       }
    static get DailyError()           { return DailyError;           }
    static get DailyTimesError()      { return DailyTimesError;      }
    static get DailyStartTimesError() { return DailyStartTimesError; }
    static get DailyEndTimesError()   { return DailyEndTimesError;   }
    static get DailyPauseError()      { return DailyPauseError;      }
    static get DailyPauseStartError() { return DailyPauseStartError; }
    static get DailyPauseEndError()   { return DailyPauseEndError;   }
    static get SignatureError()       { return SignatureError;       }
    static get StatusError()          { return StatusError;          }

    static get LegalError()           { return LegalError;           }
    static get DailyLegalError()      { return DailyLegalError;      }
    static get DailyPauseLegalError() { return DailyPauseLegalError; }
  }

  class TechnicalError       extends ValidationErrorFactory { }

  class DailyError           extends TechnicalError { }
  class DailyTimesError      extends TechnicalError { }
  class DailyStartTimesError extends TechnicalError { }
  class DailyEndTimesError   extends TechnicalError { }
  class DailyPauseError      extends TechnicalError { }
  class DailyPauseStartError extends TechnicalError { }
  class DailyPauseEndError   extends TechnicalError { }
  class SignatureError       extends TechnicalError { }

  class StatusError          extends ValidationErrorFactory { }

  class LegalError           extends ValidationErrorFactory { }

  class DailyLegalError      extends LegalError { }
  class DailyPauseLegalError extends LegalError { }

  return ValidationErrorFactory;
}]);
